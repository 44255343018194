import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Grid,
  Paper,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { grey } from "@material-ui/core/colors";
import Link from "@material-ui/core/Link";
import store from "../state/space";
import loginService from "../service/login";
import EmailIcon from "@material-ui/icons/Email";

import { Copyright } from "../components";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: grey[100],
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  message: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  message2: {
    marginTop: theme.spacing(2),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submit = async () => {
    setEmailSent(false);
    setErrorMessage("");
    if (email === "") {
      setErrorMessage("Please enter your email");
      return;
    }
    try {
      const res = await loginService.passwordResetRequest({
        email,
      });
      if (res === "ok") {
        setEmailSent(true);
      } else if (res.error === "User not found") {
        setErrorMessage("Error: Unregistred Email.");
      } else {
        setErrorMessage("Error: Problem occurred sending Email.");
        console.log("Error :", err);
      }
    } catch (err) {
      setErrorMessage("Error: Problem occurred sending Email.");
      console.log(err);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (!isValidEmail(email)) {
      setIsButtonDisabled(true);
    } else setIsButtonDisabled(false);
  };

  return (
    <Container component="main" maxWidth={!emailSent ? "xs" : "sm"}>
      <CssBaseline />
      <div className={classes.paper}>
        <LockOutlinedIcon color="primary" fontSize="large" />
        <Typography component="h1" variant="h5" align="center">
          Reset Password Request
        </Typography>
        {emailSent ? (
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Paper
                className={classes.message}
                elevation={0}
                variant="success"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <EmailIcon
                    color="error"
                    align="center"
                    style={{
                      marginRight: "0.2em",
                    }}
                  />
                  <Typography variant="h6" align="center">
                    Email Sent!
                  </Typography>
                </Box>
                <Typography
                  style={{ marginTop: "1em" }}
                  variant="body2"
                  align="center"
                >
                  You will receive a link at the email address you provided.{" "}
                  <br />
                  Please use this link to reset your password.
                </Typography>
                <Typography variant="body2" align="center">
                  It may take a few minutes for the email to arrive.
                </Typography>
              </Paper>
              <Grid container justify="flex-end" style={{ marginTop: "1em" }}>
                <Grid item>
                  <Link onClick={() => setEmailSent(false)} variant="body2">
                    Re-send email
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => handleEmailChange(e)}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={() => submit()}
              disabled={isButtonDisabled}
            >
              Request
            </Button>
            {errorMessage !== "" && (
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
            )}
            <Grid container justify="flex-end" style={{ marginTop: "1em" }}>
              <Grid item>
                <Link
                  href={`/#/login`}
                  onClick={() => setEmailSent(true)}
                  variant="body2"
                >
                  Back to sign-in
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withRouter(ResetPassword);
