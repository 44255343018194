import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import "./App.css";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import SignIn from "./views/SignIn";
import Main from "./views/main";
import SignUp from "./views/SignUp";

import store from "./state/space";
import LoginService from "./service/login";
import ResetPassword from "./views/ResetPassword";
import ResetLinkExpired from "./views/ResetLinkExpired";
import ResetLinkSuccess from "./views/ResetPasswordSuccess";
import OrgInvite from "./views/OrgInvite";
import setPassword from "./views/setPassword";
import PasswordResetRequest from "./views/PasswordResetRequest";

function PrivateRoutes() {
  return (
    <Switch>
      <Route path="/" component={Main} />
    </Switch>
  );
}

const PublicRoutes = observer(function PublicRoutes() {
  console.log("LOGGED IN", store.isLoggedIn);

  return (
    <Switch>
      <Route path="/register" component={SignUp} />

      <Route
        path="/login"
        render={({ location }) => {
          if (store.isLoggedIn) {
            const urlSearchParams = new URLSearchParams(decodeURIComponent(location.search));
            const params = Object.fromEntries(urlSearchParams.entries());
            const redirectUrl = new URL(params.redirect);
            redirectUrl.searchParams.set("token", encodeURIComponent(store.session.access_token));
            window.location.replace(redirectUrl);
            return null;
          }
          return <SignIn />;
        }}
      />
      {/* <Route path="/confirm-email" component={ResetPassword} /> */}
      <Route path="/reset-password/:id/:token" component={ResetPassword} exact />
      <Route path="/password-reset-request" component={PasswordResetRequest} exact />
      <Route path="/reset-password/:id/:token/expired" component={ResetLinkExpired} />
      <Route path="/reset-password/:id/:token/success" component={ResetLinkSuccess} />
      {/* <Route path="/org-invite/:id" component={OrgInvite} /> */}
      <Route path="/set-password/:id" component={setPassword} />
    </Switch>
  );
});

function App() {
  return (
    <div className="App">
      {
        //!store.isLoggedIn() ? (
        <PublicRoutes />
        //) : <PrivateRoutes />
      }
    </div>
  );
}

export default withRouter(App);
